import React from 'react'
import { useSystemConfig } from '../lib/use-system-config'
import ErrorPage from '../modules/ErrorPage'

const PageNotFound = () => {
  const systemConfig = useSystemConfig()
  return (
    <ErrorPage
      statusCode={404}
      errorType="pageNotFound"
      intercomId={systemConfig.intercomId}
    />
  )
}

export default PageNotFound
